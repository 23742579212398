import AxiosService from "@/services/AxiosService";

class StatisticsService {
  async getStatistics(payload) {
    return await AxiosService.get(`/v1/statistics`, { params: payload })
      .then((response) => {
        return response.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  async getBrowsers() {
    return await AxiosService.get(`/v1/browsers`)
      .then((response) => {
        return response.data.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  async getLanguages() {
    return await AxiosService.get(`/v1/languages`, {})
      .then((response) => {
        return response.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  async getOs() {
    return await AxiosService.get(`/v1/os`)
      .then((response) => {
        return response.data.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  async getOsVersions() {
    return await AxiosService.get(`/v1/os-versions`)
      .then((response) => {
        return response.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  async getDeviceType() {
    return await AxiosService.get(`/v1/devices-type`)
      .then((response) => {
        return response.data.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  async getSources() {
    return await AxiosService.get(`/v1/sources`)
      .then((response) => {
        return response.data.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  getFinanceHistory() {
    return AxiosService.get('/v1/finances/history')
      .then((resp) => {
        return resp.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  getUserCampaigns() {
    return AxiosService.get('/v1/user-campaigns')
      .then((resp) => {
        return resp.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  getInvoice(payload) {
    return AxiosService.get(`/v1/finances/invoice/${payload}`)
      .then((resp) => {
        return resp.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }
  async getUnreportedDeposits() {
    return await AxiosService.get('/v1/ga4-unreported-deposits')
      .then((response) => {
        return response.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }
  async setDepositsReported(payload) {
    return await AxiosService.post('/v1/ga4-set-deposits-reported', payload)
      .then((response) => {
        return response.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  async downloadCSV(payload) {
    return await AxiosService.get('/v1/download-csv-statistics', { params: payload })
      .then((response) => {
        return response.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }
}
export default new StatisticsService();

import AxiosService from "@/services/AxiosService";

class CampaignsService {
  async getPostBackLog() {
    return await AxiosService.get('/v1/campaigns/postback', {})
      .then((resp) => {
        return resp.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  async setComment(payload) {
    return await AxiosService.post(`/v1/campaign/${payload.id}/comment`, payload)
      .then((response) => {
        return response.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  async getUniqueCampaigns(payload) {
    return await AxiosService.post('/v1/get-unique-campaigns-traffic-type', payload)
      .then((response) => {
        return response.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  async getGroups(type) {
    if (type === 'all') {
      return await AxiosService.get(`/v1/groups`, {})
        .then((resp) => {
          return resp.data
        })
        .catch(function (error) {
          console.log(error)
        })
    } else {
      return await AxiosService.get(`/v1/groups?traffic_type=${type}`, {})
        .then((resp) => {
          return resp.data
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }

  validateMacrosLink(payload) {
    return AxiosService.post('/v1/validate-url-gsb', payload)
      .then((response) => {
        return response
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  async getTemplate(payload) {
    return await AxiosService.get(`/v1/groups/${payload.id}/templates/${payload.template_id}`)
      .then((response) => {
        return response.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  async deleteGroup(id) {
    return await AxiosService.delete(`/v1/groups/${id}`)
      .then((response) => {
        return response.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  async createGroup(payload) {
    return await AxiosService.post(`/v1/groups`, payload)
      .then((response) => {
        return response.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  async editGroup(payload) {
    const payloadId = payload.id
    const payloadInside = payload.inside
    return await AxiosService.patch(`/v1/groups/${payloadId}`, payloadInside)
      .then((response) => {
        return response.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  async updateTemplate(payload) {
    return await AxiosService.put(`/v1/groups/${payload.id}/templates/${payload.template_id}`, payload)
      .then((response) => {
        return response.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  async saveTemplate(payload) {
    return await AxiosService.post(`/v1/groups/${payload.id}/templates`, payload)
      .then((response) => {
        return response.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  async applyTemplate(payload) {
    return await AxiosService.post(`/v1/groups/${payload.id}/apply-group-template`, payload)
      .then((response) => {
        return response.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  async createCampaign(payload) {
    return await AxiosService.post(`/v1/campaigns`, payload)
      .then((response) => {
        return response.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  async editCampaign(payload) {
    return await AxiosService.post(`/v1/campaigns/${payload.id}`, payload.params)
      .then((response) => {
        return response.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  async getFeeds(payload) {
    return await AxiosService.get(
      `/v1/feeds/${payload.country}/${payload.device}/traffic_type=${payload.type}`,
      {}
    )
      .then((resp) => {
        return resp.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  async getCampaign(payload) {
    return await AxiosService.get(`/v1/campaigns/${payload}`)
      .then((response) => {
        return response.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  async getAllCampaignsRules() {
    return await AxiosService.get(`/v1/campaigns-rules`)
      .then((response) => {
        return response.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  async getAllBadges() {
    return await AxiosService.get(`/v1/badges`)
      .then((response) => {
        return response.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  async getBrowsers() {
    return await AxiosService.get(`/v1/campaign-browsers`)
      .then((response) => {
        return response.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  async getOsVersions() {
    return await AxiosService.get(`/v1/os-versions`)
      .then((response) => {
        return response.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  async getFilterCampaigns(payload) {
    return await AxiosService.get(`/v1/campaigns`, { params: payload })
      .then((response) => {
        return response.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  async getOffers() {
    return await AxiosService.get(`/v1/campaign-categories`)
      .then((response) => {
        return response.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  async setOffers(payload) {
    return await AxiosService.post(`/v1/campaign-categories`, {
      params: payload,
    })
      .then((response) => {
        return response.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  async cloneCampaigns(payload) {
    return await AxiosService.post(`/v1/campaigns/clone/${payload}`)
      .then((response) => {
        return response.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  async trashCampaign(payload) {
    return await AxiosService.post(`/v1/campaigns-trash`, payload)
      .then((response) => {
        return response.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  async setMassUrl(payload) {
    return await AxiosService.post(`/v1/campaigns-links`, payload)
      .then((response) => {
        return response.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  async setMassPrice(payload) {
    return await AxiosService.post(`/v1/campaigns-update-price`, payload)
      .then((response) => {
        return response.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }
  async setMassGroup(payload) {
    return await AxiosService.post(`/v1/campaigns-set-group`, payload)
      .then((response) => {
        return response.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  async setMassAudience(payload) {
    return await AxiosService.post(`/v1/campaign-audiences`, payload)
      .then((response) => {
        return response.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }
  async setSourcePrice(payload) {
    const params = payload.params
    const id = payload.id
    return await AxiosService.post(`/v1/campaigns/${id}/update-subacc-prices`, params)
      .then((response) => {
        return response.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  async resetSourcePrice(payload) {
    return await AxiosService.delete(`/v1/campaigns/${payload.id}/reset-subacc-price`, { params: payload.params })
      .then((response) => {
        return response.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  async setOwnStatus(payload) {
    return await AxiosService.post(`/v1/campaigns-run-stop`, payload)
      .then((response) => {
        return response.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  async resetLeads(payload) {
    return await AxiosService.post(`/v1/campaigns-reset-leads`, payload)
      .then((response) => {
        return response.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  async getCampaignSources(payload) {
    return await AxiosService.get(`/v1/campaigns/${payload.id}/subaccs`, {
      params: payload.set,
    })
      .then((response) => {
        return response.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  async addSourceToBlacklist(payload) {
    return await AxiosService.patch(`/v1/campaigns/${payload.id}/add-source-to-blacklist`, {
      source: payload.source,
    })
      .then((response) => {
        return response.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  async removeSourceFromBlacklist(payload) {
    return await AxiosService.patch(`/v1/campaigns/${payload.id}/remove-source-from-blacklist`, {
      source: payload.source,
    })
      .then((response) => {
        return response.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  async getTrafficSources(payload) {
    return await AxiosService.get(`/v1/campaigns/${payload}/subacc-traffic-sources`)
      .then((response) => {
        return response.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }
  async getChartTraffic(payload) {
    return await AxiosService.get(`/v1/click-price-chart`, { params: payload })
      .then((response) => {
        return response.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  async getAutoRulesPrice(payload) {
    return await AxiosService.get('/v1/autorules-prices', {
      params: payload,
    })
      .then((response) => {
        return response.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  async resetBids(payload) {
    return await AxiosService.delete(`/v1/campaigns/${payload.id}/reset-subacc-prices`)
      .then((response) => {
        return response.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }
}

export default new CampaignsService();

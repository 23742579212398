<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script
  type="text/javascript"
  src="https://app.getbeamer.com/js/beamer-embed.js"
  defer="defer"
></script>

<script>
import ServiceStatistics from "@/api/serviceStatistics";
import {sendMetaEvent} from "@/services/metaEvents.js";
import { mapGetters } from "vuex";
import { setLangHeader } from "@/services/AxiosService";
 
export default {
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Push.House is a self-serve Premium Ad Network, empowering advertisers with essential tools and features for successful online advertising.",
      },
    ],
    title: "Push.House - Premium Advertising Network",
    titleTemplate: "%s | Push.House",
  },
  data() {
    return {
      financesInterval: null,
      beamer: null
    };
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
    layout() {
      return this.$route.meta.layout || 'default-layout';
    },
  },
  mounted() {
    this.$intercom?.boot();
    setTimeout(() => this.beamer = window.Beamer, 1000)
  },
  beforeDestroy() {
    clearInterval(this.financesInterval);
  },
  watch: {
    user: {
      immediate: true,
      handler(val) {
        if (val.id) {
          this.$intercom?.boot({
            user_id: val.id,
            name: val.name,
            email: val.email,
          });
          if (this.user.is_email_verified !== false) {
            this.checkUnreportedDeposits();
            this.financesInterval = setInterval(() => {
              this.checkUnreportedDeposits();
            }, 60000);
          }
        }
      },
    },
    "$intercom.ready": function () {
      if (this.$intercom.ready) {
        this.$intercom.hide();
      }
    },
    "$i18n.locale": function () {
      setLangHeader(localStorage.getItem("lang"));
      if (this.beamer) {
        this.beamer.update({
          ...beamer_config,
          language: localStorage.getItem("lang") === "ru" ? "ru" : "en",
        });
      }
    },
  },
  methods: {
    checkUnreportedDeposits() {
      let deposits = [];
      ServiceStatistics.getUnreportedDeposits()
        .then((res) => {
          if (res && (res.status === 200) & (res.data.length > 0)) {
            for (let deposit of res.data) {

              this.$gtm.trackEvent({
                // lowercase events name for gtm
                event: "purchase",
                event_id: `${deposit.id}`,
                value: +deposit.amount,
                currency: "USD",
                transaction_id: deposit.id,
                items: [
                  {
                    item_id: "1",
                    item_name: "TopUp",
                    quantity: 1,
                    price: +deposit.amount,
                  },
                ],
              });
              deposits.push(deposit.id);
              // uppercase events name for meta
              sendMetaEvent("/panel/finances", "Purchase", +deposit.amount, `${deposit.id}`);
            }
           
          }
        })
        .then(() => {
          if (deposits.length > 0) {
            let payload = { ids: deposits };
            ServiceStatistics.setDepositsReported(payload);
          }
        });
    },
  },
};
</script>
